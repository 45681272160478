import { createAsyncThunk } from "@reduxjs/toolkit";

import { fetchWrapper, generateUpdateApiQueryParams } from "_utils";

// create slice
const baseUrl = process.env.REACT_APP_API_URL;
const sliceName = "stockproduct";

// APIs

// Stock Product
export const getStockProductMetaData = createAsyncThunk(
  `${sliceName}/getStockProductMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/stock-product/getMetadata`)
);

export const getStockProductRowData = createAsyncThunk(
  `${sliceName}/getStockProductRowData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/stock-product/getAll?${params || ""}`)
);

export const deleteStockProduct = createAsyncThunk(
  `${sliceName}/deleteStockProduct`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/stock-product/delete${deletePermanent ? "-permanent" : ""}/${id}`,
      { id }
    );
    return res || res === "" ? { id } : null;
  }
);

export const addStockProduct = createAsyncThunk(
  `${sliceName}/addStockProduct`,
  async (values) => {
    const res = await fetchWrapper.post(`${baseUrl}/stock-product/add`, values);
    return res || res === "" ? res : null;
  }
);

export const editStockProduct = createAsyncThunk(
  `${sliceName}/editStockProduct`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/stock-products/updateStockProduct/${values.id}`,
      values
    );
    return res || res === "" ? values : null;
  }
);

export const getStockProductById = createAsyncThunk(
  `${sliceName}/getStockProductById`,
  async (apiParams) => {
    let id = apiParams?.id
    let params = undefined
    if (!id) {
      id = apiParams
    } else {
      params = generateUpdateApiQueryParams(apiParams?.params)
    }
    return await fetchWrapper.get(`${baseUrl}/stock-products/get/${id}${params ? `?${params}` : ''}`)
  }
);

export const getStockProductMetaDataByIdForVersion = createAsyncThunk(
  `${sliceName}/getStockProductMetaDataByIdForVersion`,
  async (params) => await fetchWrapper.get(`${baseUrl}/stock-product/getMetadata?versioning=true&${params || ""}`)
);

export const getStockProductRowDataByIdForVersion = createAsyncThunk(
  `${sliceName}/getStockProductRowDataByIdForVersion`,
  async ({ params, id }) => await fetchWrapper.get(`${baseUrl}/stock-product/version-history/get/${id}?${params || ""}`)
);

// Stock Tag
export const getStockTagMetaData = createAsyncThunk(
  `${sliceName}/getStockTagMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/stock-tag/getMetadata`)
);

// Stock Tag reconciliation
export const getStockTagReconciliationMetaData = createAsyncThunk(
  `${sliceName}/getStockTagReconciliationMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/stock-reconciliation/getMetadata`)
);

export const getStockTagRowData = createAsyncThunk(
  `${sliceName}/getStockTagRowData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/stock-tag/getAll?${params || ""}`)
);

export const updateStockTagAction = createAsyncThunk(
  `${sliceName}/updateStockTagAction`,
  async ({stockTagId, actionPayload}) => {
    const res = await fetchWrapper.post(`${baseUrl}/stock-tag/update/${stockTagId}`, actionPayload);
    return res || res === "" ? res : null;
  }
);

export const getStockReconciliationVersionHistoryRowData = createAsyncThunk(
  `${sliceName}/getStockReconciliationVersionHistoryRowData`,
  async ({ params, id }) =>
    await fetchWrapper.get(`${baseUrl}/stock-reconciliation/version-history/get/${id}?${params || ""}`)
);

export const getJewelleryProductRowData = createAsyncThunk(
  `${sliceName}/getJewelleryProductRowData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/jewellery-product/getAll?${params || ""}`)
);

export const getJewelleryProductList = createAsyncThunk(
  `${sliceName}/getJewelleryProductList`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/jewellery-product/getAll?${params || ""}`)
);


export const getJewelleryProductMetaData = createAsyncThunk(
  `${sliceName}/getJewelleryProductMetaData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/jewellery-product/getMetadata?${params || ""}`)
);

export const deleteStockTag = createAsyncThunk(
  `${sliceName}/deleteStockTag`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/stock-tag/delete${deletePermanent ? "-permanent" : ""}/${id}`,
      { id }
    );
    return res || res === "" ? { id } : null;
  }
);

export const addStockTag = createAsyncThunk(
  `${sliceName}/addStockTag`,
  async (values) => {
    const res = await fetchWrapper.post(`${baseUrl}/stock-tag/add`, values);
    return res || res === "" ? res : null;
  }
);

export const editStockTag = createAsyncThunk(
  `${sliceName}/editStockTag`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/stock-tag/update/${values.id}`,
      values
    );
    return res || res === "" ? values : null;
  }
);

export const getStockTagById = createAsyncThunk(
  `${sliceName}/getStockTagById`,
  async (apiParams) => {
    let id = apiParams?.id
    let params = undefined
    if (!id) {
      id = apiParams
    } else {
      params = generateUpdateApiQueryParams(apiParams?.params)
    }
    return await fetchWrapper.get(`${baseUrl}/stock-tag/get/${id}${params ? `?${params}` : ''}`)
  }
);

export const getStockTagMetaDataByIdForVersion = createAsyncThunk(
  `${sliceName}/getStockTagMetaDataByIdForVersion`,
  async (params) => await fetchWrapper.get(`${baseUrl}/stock-tag/getMetadata?versioning=true&${params || ""}`)
);

export const getStockTagRowDataByIdForVersion = createAsyncThunk(
  `${sliceName}/getStockTagRowDataByIdForVersion`,
  async ({ params, id }) => await fetchWrapper.get(`${baseUrl}/stock-tag/version-history/get/${id}?${params || ""}`)
);


// Inventory Tag
export const getInventoryTagMetaData = createAsyncThunk(
  `${sliceName}/getInventoryTagMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/inventory-tag/getMetadata`)
);

export const getInventoryTagReconciliationMetaData = createAsyncThunk(
  `${sliceName}/getInventoryTagReconciliationMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/inventory-tag-reconciliation/getMetadata`)
);

export const getInventoryTagRowData = createAsyncThunk(
  `${sliceName}/getInventoryTagRowData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/inventory-tag/getAll?${params || ""}`)
);

export const getInventoryTagList = createAsyncThunk(
  `${sliceName}/getInventoryTagList`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/inventory-tag/dropdown/getAll?${params || ""}`)
);

export const getInventoryTagReconciliationVersionHistoryRowData = createAsyncThunk(
  `${sliceName}/getInventoryTagReconciliationVersionHistoryRowData`,
  async ({ params, id }) =>
    await fetchWrapper.get(`${baseUrl}/inventory-tag-reconciliation/version-history/get/${id}?${params || ""}`)
);


export const deleteInventoryTag = createAsyncThunk(
  `${sliceName}/deleteInventoryTag`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/inventory-tag/delete${deletePermanent ? "-permanent" : ""}/${id}`,
      { id }
    );
    return res || res === "" ? { id } : null;
  }
);

export const mergeInventoryTags = createAsyncThunk(
  `${sliceName}/mergeInventoryTags`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/stock-tag/merge`,
      values
    );
    return res || res === "" ? res : null;
  }
);

export const splitStockTags = createAsyncThunk(
  `${sliceName}/splitStockTags`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/stock-tag/split`,
      values
    );
    return res || res === "" ? values : null;
  }
);

export const splitInventoryTags = createAsyncThunk(
  `${sliceName}/splitInventoryTags`,
  async (values) => {
    const res = await fetchWrapper.post(`${baseUrl}/stock/split-melts`, values);
    return res || res === "" ? res : null;
  }
);


export const addInventoryTag = createAsyncThunk(
  `${sliceName}/addInventoryTag`,
  async (values) => {
    const res = await fetchWrapper.post(`${baseUrl}/inventory-tag/add`, values);
    return res || res === "" ? res : null;
  }
);

export const editInventoryTag = createAsyncThunk(
  `${sliceName}/editInventoryTag`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/inventory-tag/update/${values.id}`,
      values
    );
    return res || res === "" ? values : null;
  }
);

export const getInventoryTagById = createAsyncThunk(
  `${sliceName}/getInventoryTagById`,
  async (id) => await fetchWrapper.get(`${baseUrl}/inventory-tag/get/${id}`)
);

export const getInventoryTagMetaDataByIdForVersion = createAsyncThunk(
  `${sliceName}/getInventoryTagMetaDataByIdForVersion`,
  async (params) => await fetchWrapper.get(`${baseUrl}/inventory-tag/getMetadata?versioning=true&${params || ""}`)
);

export const getInventoryTagRowDataByIdForVersion = createAsyncThunk(
  `${sliceName}/getInventoryTagRowDataByIdForVersion`,
  async ({ params, id }) => await fetchWrapper.get(`${baseUrl}/inventory-tag/version-history/get/${id}?${params || ""}`)
);

// Stock Tray
export const getStockTrayMetaData = createAsyncThunk(
  `${sliceName}/getStockTrayMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/stock-tray/getMetadata`)
);

export const getStockTrayRowData = createAsyncThunk(
  `${sliceName}/getStockTrayRowData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/stock-tray/getAll?${params || ""}`)
);

export const getStockTagLifeCycle = createAsyncThunk(
  `${sliceName}/getStockTagLifeCycle`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/stock-tag/${params || ""}/history/getAll`)
);

export const deleteStockTray = createAsyncThunk(
  `${sliceName}/deleteStockTray`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/stock-tray/delete${deletePermanent ? "-permanent" : ""}/${id}`,
      { id }
    );
    return res || res === "" ? { id } : null;
  }
);

export const addStockTray = createAsyncThunk(
  `${sliceName}/addStockTray`,
  async (values) => {
    const res = await fetchWrapper.post(`${baseUrl}/stock-tray/add`, values);
    return res || res === "" ? res : null;
  }
);

export const editStockTray = createAsyncThunk(
  `${sliceName}/editStockTray`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/stock-tray/updateStockTray/${values.id}`,
      values
    );
    return res || res === "" ? values : null;
  }
);

export const getStockTrayById = createAsyncThunk(
  `${sliceName}/getStockTrayById`,
  async (apiParams) => {
    let id = apiParams?.id
    let params = undefined
    if (!id) {
      id = apiParams
    } else {
      params = generateUpdateApiQueryParams(apiParams?.params)
    }
    return await fetchWrapper.get(`${baseUrl}/stock-tray/get/${id}${params ? `?${params}` : ''}`)
  }
);

export const getStockTrayMetaDataByIdForVersion = createAsyncThunk(
  `${sliceName}/getStockTrayMetaDataByIdForVersion`,
  async (params) => await fetchWrapper.get(`${baseUrl}/stock-tray/getMetadata?versioning=true&${params || ""}`)
);

export const getStockTrayRowDataByIdForVersion = createAsyncThunk(
  `${sliceName}/getStockTrayRowDataByIdForVersion`,
  async ({ params, id }) => await fetchWrapper.get(`${baseUrl}/stock-tray/version-history/get/${id}?${params || ""}`)
);

// Inventory Tray
export const getInventoryTrayMetaData = createAsyncThunk(
  `${sliceName}/getInventoryTrayMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/inventory-tray/getMetadata`)
);

export const getInventoryTrayRowData = createAsyncThunk(
  `${sliceName}/getInventoryTrayRowData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/inventory-tray/getAll?${params || ""}`)
);

export const deleteInventoryTray = createAsyncThunk(
  `${sliceName}/deleteInventoryTray`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/inventory-tray/delete${deletePermanent ? "-permanent" : ""}/${id}`,
      { id }
    );
    return res || res === "" ? { id } : null;
  }
);

export const addInventoryTray = createAsyncThunk(
  `${sliceName}/addInventoryTray`,
  async (values) => {
    const res = await fetchWrapper.post(`${baseUrl}/inventory-tray/add`, values);
    return res || res === "" ? res : null;
  }
);

export const editInventoryTray = createAsyncThunk(
  `${sliceName}/editInventoryTray`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/inventory-tray/updateInventoryTray/${values.id}`,
      values
    );
    return res || res === "" ? values : null;
  }
);

export const getInventoryTrayById = createAsyncThunk(
  `${sliceName}/getInventoryTrayById`,
  async (apiParams) => {
    let id = apiParams?.id
    let params = undefined
    if (!id) {
      id = apiParams
    } else {
      params = generateUpdateApiQueryParams(apiParams?.params)
    }
    return await fetchWrapper.get(`${baseUrl}/inventory-tray/get/${id}${params ? `?${params}` : ''}`)
  }
);

export const getInventoryTrayMetaDataByIdForVersion = createAsyncThunk(
  `${sliceName}/getInventoryTrayMetaDataByIdForVersion`,
  async (params) => await fetchWrapper.get(`${baseUrl}/inventory-tray/getMetadata?versioning=true&${params || ""}`)
);

export const getInventoryTrayRowDataByIdForVersion = createAsyncThunk(
  `${sliceName}/getInventoryTrayRowDataByIdForVersion`,
  async ({ params, id }) => await fetchWrapper.get(`${baseUrl}/inventory-tray/version-history/get/${id}?${params || ""}`)
);

export const getInventoryTagLifeCycle = createAsyncThunk(
  `${sliceName}/getInventoryTagLifeCycle`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/inventory-tag/${params || ""}/history/getAll`)
);

export const getInventoryTagActionsList = createAsyncThunk(
  `${sliceName}/getInventoryTagActionsList`,
  async (objectId) => await fetchWrapper.get(`${baseUrl}/inventory-tag/${objectId}/action/InventoryTag`)
);

export const getStockTagActionsList = createAsyncThunk(
  `${sliceName}/getStockTagActionsList`,
  async (objectId) => await fetchWrapper.get(`${baseUrl}/stock-tag/${objectId}/action/StockTag`)
);


export const addStockNrv = createAsyncThunk(
  `${sliceName}/addStockNrv`,
  async (values) => {
    const res = await fetchWrapper.post(`${baseUrl}/stock-nrv-details/add`, values);
    return res || res === "" ? res : null;
  }
);

export const getStockNrvList = createAsyncThunk(
  `${sliceName}/getStockNrvList`,
  async () => await fetchWrapper.get(`${baseUrl}/stock-nrv-details/getAll`)
);

export const getStockNrvVersionHistory = createAsyncThunk(
  `${sliceName}/getStockNrvVersionHistory`,
  async (id) => await fetchWrapper.get(`${baseUrl}/stock-product-nrv/version-history/get/${id}`)
);


export const addInventoryNrv = createAsyncThunk(
  `${sliceName}/addInventoryNrv`,
  async (values) => {
    const res = await fetchWrapper.post(`${baseUrl}/inventory-nrv-details/add`, values);
    return res || res === "" ? res : null;
  }
);

export const getInventoryNrvList = createAsyncThunk(
  `${sliceName}/getInventoryNrvList`,
  async () => await fetchWrapper.get(`${baseUrl}/inventory-nrv-details/getAll`)
);

export const getInventoryNrvVersionHistory = createAsyncThunk(
  `${sliceName}/getInventoryNrvVersionHistory`,
  async (id) => await fetchWrapper.get(`${baseUrl}/inventory-nrv-details/version-history/get/${id}`)
);

export const addStockTrayBulkUpdates = createAsyncThunk(
  `${sliceName}/addStockTrayBulkUpdates`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/stock-tray/bulk-update`,
      values
    );
    return res || res === "" ? res : null;
  }
);

export const generateSemiFinishGoods = createAsyncThunk(
  `${sliceName}/generateSemiFinishGoods`,
  async (values) => {
    const res = await fetchWrapper.post(`${baseUrl}/semi-fg-product/add`, values);
    return res || res === "" ? res : null;
  }
);

export const getInventoryTypeList = createAsyncThunk(
  `${sliceName}/getInventoryTypeList`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/inventory-type/dropdown/getAll?${params || ""}`)
);
