import { createAsyncThunk } from "@reduxjs/toolkit";

import { fetchWrapper } from "_utils";

// create slice
const baseUrl = process.env.REACT_APP_API_URL;
const sliceName = "orders";

// APIs
//Orders Data
/* table Meta Data */
export const getOrderLineItemMetaData = createAsyncThunk(
  `${sliceName}/getOrderLineItemMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/order-line-item/getMetadata`)
);

export const getOrderLineItemRowData = createAsyncThunk(
  `${sliceName}/getOrderLineItemGetA`,
  async () => await fetchWrapper.get(`${baseUrl}/order-line-item/getAll`)
);

export const deleteOrderLineItem = createAsyncThunk(
  `${sliceName}/deleteOrderLineItem`,
  async ({ userId }) =>
    await fetchWrapper.post(
      `${baseUrl}/order-external-states/delete/${userId}`,
      { userId }
    )
);

// Prlineitem
export const getPrLineItemMetaData = createAsyncThunk(
  `${sliceName}/getPrLineItemMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/pr-line-item/getMetadata`)
);

export const getPrLineItemRowData = createAsyncThunk(
  `${sliceName}/getPrLineItemRowData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/pr-line-item/getAll?${params || ""}`)
);

export const getSupplyingVendorRowData = createAsyncThunk(
  `${sliceName}/getSupplyingVendorRowData`,
  async (params) => await fetchWrapper.get(`${baseUrl}/vendor/getAll?${params || ""}`)
);

export const deletePrLineItem = createAsyncThunk(
  `${sliceName}/deletePrLineItem`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/pr-line-item/delete${
        deletePermanent ? "-permanent" : ""
      }/${id}`,
      { id }
    );
    return res || res === "" ? { id } : null;
  }
);

export const addPrLineItem = createAsyncThunk(
  `${sliceName}/addPrLineItem`,
  async (values) => {
    const res = await fetchWrapper.post(`${baseUrl}/pr-line-item/add`, values);
    return res || res === "" ? res : null;
  }
);

export const createPrLineItem = createAsyncThunk(
  `${sliceName}/createPrLineItem`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/pr-line-item/create`,
      values
    );
    return res || res === "" ? res : null;
  }
);

export const editPrLineItem = createAsyncThunk(
  `${sliceName}/editPrLineItem`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/pr-line-item/edit/${values.id}`,
      values
    );
    return res || res === "" ? values : null;
  }
);

export const getPrLineItemById = createAsyncThunk(
  `${sliceName}/getPrLineItemById`,
  async (id) => await fetchWrapper.get(`${baseUrl}/pr-line-item/get/${id}`)
);

// Po
export const getPoMetaData = createAsyncThunk(
  `${sliceName}/getPoMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/po/getMetadata`)
);

export const getPoRowData = createAsyncThunk(
  `${sliceName}/getPoRowData`,
  async (params) => await fetchWrapper.get(`${baseUrl}/po/getAll?${params || ""}`)
);

export const deletePo = createAsyncThunk(
  `${sliceName}/deletePo`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/po/${
        deletePermanent ? "delete-permanent" : "deletePo"
      }/${id}`,
      { id }
    );
    return res || res === "" ? { id } : null;
  }
);

// Grn
export const getGrnMetaData = createAsyncThunk(
  `${sliceName}/getGrnMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/grn/getMetadata`)
);

export const getGrnRowData = createAsyncThunk(
  `${sliceName}/getGrnRowData`,
  async (params) => await fetchWrapper.get(`${baseUrl}/grn/getAll?${params || ""}`)
);

export const deleteGrn = createAsyncThunk(
  `${sliceName}/deleteGrn`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/grn/delete${deletePermanent ? "-permanent" : ""}/${id}`,
      { id }
    );
    return res || res === "" ? { id } : null;
  }
);

// Grn line item
export const getGrnLineItemMetaData = createAsyncThunk(
  `${sliceName}/getGrnLineItemMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/grn-line-item/getMetadata`)
);

export const getGrnLineItemRowData = createAsyncThunk(
  `${sliceName}/getGrnLineItemRowData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/grn-line-item/getAll?${params || ""}`)
);

export const deleteGrnLineItem = createAsyncThunk(
  `${sliceName}/deleteGrnLineItem`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/grn-line-item/delete${
        deletePermanent ? "-permanent" : ""
      }/${id}`,
      { id }
    );
    return res || res === "" ? { id } : null;
  }
);

// Po
export const getPoLineItemMetaData = createAsyncThunk(
  `${sliceName}/getPoLineItemMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/po-line-item/getMetadata`)
);

export const getPoLineItemRowData = createAsyncThunk(
  `${sliceName}/getPoLineItemRowData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/po-line-item/getAll?${params || ""}`)
);

export const deletePoLineItem = createAsyncThunk(
  `${sliceName}/deletePoLineItem`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/po-line-item/delete${
        deletePermanent ? "-permanent" : ""
      }/${id}`,
      { id }
    );
    return res || res === "" ? { id } : null;
  }
);

export const generateBarcodePo = createAsyncThunk(
  `${sliceName}/generateBarcodePo`,
  async (values) => {
    const res = await fetchWrapper.post(`${baseUrl}/generateBarCode`, values);
    return res || res === "" ? res : null;
  }
);

export const addPo = createAsyncThunk(`${sliceName}/addPo`, async (values) => {
  const res = await fetchWrapper.post(`${baseUrl}/po/creation`, values);
  return res || res === "" ? res : null;
});

export const editPo = createAsyncThunk(
  `${sliceName}/editPo`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/po/edit/${values.id}`,
      values
    );
    return res || res === "" ? values : null;
  }
);

// grn

export const addGrn = createAsyncThunk(
  `${sliceName}/addGrn`,
  async (values) => {
    const res = await fetchWrapper.post(`${baseUrl}/grn/create`, values);
    return res || res === "" ? res : null;
  }
);

export const editGrn = createAsyncThunk(
  `${sliceName}/editGrn`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/grn/edit/${values.id}`,
      values
    );
    return res || res === "" ? values : null;
  }
);

export const addGrnLineItem = createAsyncThunk(
  `${sliceName}/addGrnLineItem`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/grn-line-item/create`,
      values
    );
    return res || res === "" ? res : null;
  }
);

export const editGrnLineItem = createAsyncThunk(
  `${sliceName}/editGrnLineItem`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/grn-line-item/edit/${values.id}`,
      values
    );
    return res || res === "" ? values : null;
  }
);

export const getPoById = createAsyncThunk(
  `${sliceName}/getPoById`,
  async (id) => await fetchWrapper.get(`${baseUrl}/po/get/${id}`)
);

export const getGrnById = createAsyncThunk(
  `${sliceName}/getGrnById`,
  async (id) => await fetchWrapper.get(`${baseUrl}/grn/get/${id}`)
);

export const getGrnLineItemById = createAsyncThunk(
  `${sliceName}/getGrnLineItemById`,
  async (id) => await fetchWrapper.get(`${baseUrl}/grn-line-item/get/${id}`)
);

export const deletePOById = createAsyncThunk(
  `${sliceName}/deletePOById`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(`${baseUrl}/po/delete/${id}`, {
      id,
    });
    return res;
  }
);

export const addPoLineItem = createAsyncThunk(
  `${sliceName}/addPoLineItem`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/po-line-item/create`,
      values
    );
    return res || res === "" ? res : null;
  }
);

export const editPoLineItem = createAsyncThunk(
  `${sliceName}/editPoLineItem`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/po-line-item/edit/${values.id}`,
      values
    );
    return res || res === "" ? values : null;
  }
);

export const getPoLineItemById = createAsyncThunk(
  `${sliceName}/getPoLineItemById`,
  async (id) => await fetchWrapper.get(`${baseUrl}/po-line-item/get/${id}`)
);

export const deletePOLineItemById = createAsyncThunk(
  `${sliceName}/deletePOLineItemById`,
  async ({ id }) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/po-line-item/delete/${id}`,
      {
        id,
      }
    );
    return res;
  }
);

// OrderLineItemDemand
export const getOrderLineItemDemandMetaData = createAsyncThunk(
  `${sliceName}/getOrderLineItemDemandMetaData`,
  async () =>
    await fetchWrapper.get(`${baseUrl}/order-lineitem-demand/getMetadata`)
);

export const getOrderLineItemDemandRowData = createAsyncThunk(
  `${sliceName}/getOrderLineItemDemandRowData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/order-lineitem-demand/getAll?${params || ""}`)
);

export const deleteOrderLineItemDemand = createAsyncThunk(
  `${sliceName}/deleteOrderLineItemDemand`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/order-lineitem-demand/delete${
        deletePermanent ? "-permanent" : ""
      }/${id}`,
      { id }
    );
    return res || res === "" ? { id } : null;
  }
);

//stone availability
export const getStoneAvailabilityMetaData = createAsyncThunk(
  `${sliceName}/getStoneAvailabilityMetaData`,
  async () =>
    await fetchWrapper.get(`${baseUrl}/stone-availability/getMetadata`)
);

export const getStoneDetailsRowData = createAsyncThunk(
  `${sliceName}/getStoneDetailsRowData`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/stone-availability/find`,
      values
    );
    return res || res === "" ? res : null;
  }
);

export const getStoneAvailabilityRowData = createAsyncThunk(
  `${sliceName}/getStoneAvailabilityRowData`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/stone-availability/find`,
      values
    );
    return res || res === "" ? res : null;
  }
);

//stock availability
export const getStockAvailabilityMetaData = createAsyncThunk(
  `${sliceName}/getStockAvailabilityMetaData`,
  async () =>
    await fetchWrapper.get(`${baseUrl}/stock-tag/getMetadata`)
);

export const getStockAvailabilityRowData = createAsyncThunk(
  `${sliceName}/getStockAvailabilityRowData`,
  async (id) =>
    await fetchWrapper.get(`${baseUrl}/order-lineitem-demand/get-available-stocktag/${id}`)
);


// OrderLineItemDemandStone
export const getOrderDemandLineItemStoneMetaData = createAsyncThunk(
  `${sliceName}/getOrderDemandLineItemStoneMetaData`,
  async () =>
    await fetchWrapper.get(
      `${baseUrl}/order-demand-line-item-stones/getMetadata`
    )
);

export const getOrderDemandLineItemStoneRowData = createAsyncThunk(
  `${sliceName}/getOrderDemandLineItemStoneRowData`,
  async (params) =>
    await fetchWrapper.get(
      `${baseUrl}/order-demand-line-item-stones/getAll?${params || ""}`
    )
);

export const deleteOrderDemandLineItemStone = createAsyncThunk(
  `${sliceName}/deleteOrderDemandLineItemStone`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/order-demand-line-item-stones/delete${
        deletePermanent ? "-permanent" : ""
      }/${id}`,
      { id }
    );
    return res || res === "" ? { id } : null;
  }
);

export const addOrderLineItemDemand = createAsyncThunk(
  `${sliceName}/addOrderLineItemDemand`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/order-lineitem-demand/order/create/${values?.userId}/${values?.orderId}`,
      values
    );
    return res || res === "" ? res : null;
  }
);

export const createOrderLineItemDemand = createAsyncThunk(
  `${sliceName}/createOrderLineItemDemand`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/order-lineitem-demand/create`,
      values
    );
    return res || res === "" ? res : null;
  }
);

export const editOrderLineItemDemand = createAsyncThunk(
  `${sliceName}/editOrderLineItemDemand`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/order-lineitem-demand/edit/${values.id}`,
      values
    );
    return res || res === "" ? values : null;
  }
);

export const getOrderLineItemDemandById = createAsyncThunk(
  `${sliceName}/getOrderLineItemDemandById`,
  async (id) =>
    await fetchWrapper.get(`${baseUrl}/order-lineitem-demand/get/${id}`)
);

//demand stone

export const createOrderDemandLineItemStone = createAsyncThunk(
  `${sliceName}/createOrderDemandLineItemStone`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/order-demand-line-item-stones/create`,
      values
    );
    return res || res === "" ? res : null;
  }
);

export const editOrderDemandLineItemStone = createAsyncThunk(
  `${sliceName}/editOrderDemandLineItemStone`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/order-demand-line-item-stones/edit/${values.id}`,
      values
    );
    return res || res === "" ? values : null;
  }
);

export const getOrderDemandLineItemStoneById = createAsyncThunk(
  `${sliceName}/getOrderDemandLineItemStoneById`,
  async (id) =>
    await fetchWrapper.get(`${baseUrl}/order-demand-line-item-stones/get/${id}`)
);

export const getOrderLineItemDemandVersionMetaData = createAsyncThunk(
  `${sliceName}/getOrderLineItemDemandVersionMetaData`,
  async (params) =>
    await fetchWrapper.get(
      `${baseUrl}/order-lineitem-demand/getMetadata?versioning=true&${params || ""}`
    )
);

export const getOrderLineItemDemandRowDataById = createAsyncThunk(
  `${sliceName}/getOrderLineItemDemandRowDataById`,
  async ({ params, id }) =>
    await fetchWrapper.get(
      `${baseUrl}/order-lineitem-demand/version-history/get/${id}?${params || ""}`
    )
);

// demand stone
export const getOrderDemandLineItemStoneVersionMetaData = createAsyncThunk(
  `${sliceName}/getOrderDemandLineItemStoneVersionMetaData`,
  async (params) =>
    await fetchWrapper.get(
      `${baseUrl}/order-demand-line-item-stones/getMetadata?versioning=true&${params || ""}`
    )
);

export const getOrderDemandLineItemStoneRowDataById = createAsyncThunk(
  `${sliceName}/getOrderDemandLineItemStoneRowDataById`,
  async ({ params, id }) =>
    await fetchWrapper.get(
      `${baseUrl}/order-line-item-demand-full-products/version-history/get/${id}?${params || ""}`
    )
);

export const getPrLineItemVersionMetaData = createAsyncThunk(
  `${sliceName}/getPrLineItemVersionMetaData`,
  async (params) =>
    await fetchWrapper.get(
      `${baseUrl}/pr-line-item/getMetadata?versioning=true&${params || ""}`
    )
);

export const getPrLineItemVersionRowDataById = createAsyncThunk(
  `${sliceName}/getPrLineItemRowDataById`,
  async ({ params, id }) =>
    await fetchWrapper.get(
      `${baseUrl}/pr-line-item/version-history/get/${id}?${params || ""}`
    )
);

export const getPoLineItemVersionMetaData = createAsyncThunk(
  `${sliceName}/getPoLineItemVersionMetaData`,
  async (params) =>
    await fetchWrapper.get(
      `${baseUrl}/po-line-item/getMetadata?versioning=true&${params || ""}`
    )
);

export const getPoLineItemVersionRowDataById = createAsyncThunk(
  `${sliceName}/getPoLineItemVersionRowDataById`,
  async ({ params, id }) =>
    await fetchWrapper.get(
      `${baseUrl}/po-line-item/version-history/get/${id}?${params || ""}`
    )
);

export const getPoVersionMetaData = createAsyncThunk(
  `${sliceName}/getPoVersionMetaData`,
  async (params) =>
    await fetchWrapper.get(
      `${baseUrl}/po/getMetadata?versioning=true&${params || ""}`
    )
);

export const getPoVersionRowDataById = createAsyncThunk(
  `${sliceName}/getPoVersionRowDataById`,
  async ({ params, id }) =>
    await fetchWrapper.get(`${baseUrl}/po/version-history/get/${id}?${params || ""}`)
);

export const getGrnLineItemVersionMetaData = createAsyncThunk(
  `${sliceName}/getGrnLineItemVersionMetaData`,
  async (params) =>
    await fetchWrapper.get(
      `${baseUrl}/grn-line-item/getMetadata?versioning=true&${params || ""}`
    )
);

export const getGrnLineItemVersionRowDataById = createAsyncThunk(
  `${sliceName}/getGrnLineItemVersionRowDataById`,
  async ({ params, id }) =>
    await fetchWrapper.get(
      `${baseUrl}/grn-line-item/version-history/get/${id}?${params || ""}`
    )
);

export const getGrnVersionMetaData = createAsyncThunk(
  `${sliceName}/getGrnVersionMetaData`,
  async (params) =>
    await fetchWrapper.get(
      `${baseUrl}/grn/getMetadata?versioning=true&${params || ""}`
    )
);

export const getGrnVersionRowDataById = createAsyncThunk(
  `${sliceName}/getGrnVersionRowDataById`,
  async ({ params, id }) =>
    await fetchWrapper.get(`${baseUrl}/grn/version-history/get/${id}?${params || ""}`)
);

export const getOrderLineItemDemandStates = createAsyncThunk(
  `${sliceName}/getOrderLineItemDemandStates`,
  async () =>
    await fetchWrapper.get(`${baseUrl}/order-line-item-demand-states/getAll`)
);

export const getOrderDemandLineItemStones = createAsyncThunk(
  `${sliceName}/getOrderDemandLineItemStones`,
  async () =>
    await fetchWrapper.get(`${baseUrl}/order-demand-line-item-stones/getAll`)
);

export const getStonePricesRowData = createAsyncThunk(
  `${sliceName}/getStonePricesRowData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/stone-prices/get?${params || ""}`)
);

export const getVariantStoneMap = createAsyncThunk(
  `${sliceName}/getVariantStoneMap`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/variant-stone-map/getAll?${params || ""}`)
);

export const getOrderDemandLineItemStoneStates = createAsyncThunk(
  `${sliceName}/getOrderDemandLineItemStoneStates`,
  async (params) =>
    await fetchWrapper.get(
      `${baseUrl}/order-demand-line-item-stone-states/getAll?${params || ""}`
    )
);

export const getGrnStatesRowData = createAsyncThunk(
  `${sliceName}/getGrnStatesRowData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/grn-states/getAll?${params || ""}`)
);

export const getPoLineItemStatesRowData = createAsyncThunk(
  `${sliceName}/getPoLineItemStatesRowData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/po-line-item-states/getAll?${params || ""}`)
);

// po state
export const getPoStateMetaData = createAsyncThunk(
  `${sliceName}/getPoStateMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/po-states/getMetadata`)
);

export const gePoStateRowData = createAsyncThunk(
  `${sliceName}/gePoStateRowData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/po-states/getAll?${params || ""}`)
);

export const deletePoState = createAsyncThunk(
  `${sliceName}/deletePoState`,
  async ({ id }) => await fetchWrapper.post(`${baseUrl}/po-states/delete/${id}`)
);

export const getPoStateById = createAsyncThunk(
  `${sliceName}/getPoStateById`,
  async (id) => await fetchWrapper.get(`${baseUrl}/po-states/get/${id}`)
);

export const addPoState = createAsyncThunk(
  `${sliceName}/addPoState`,
  async (values) => {
    const res = await fetchWrapper.post(`${baseUrl}/po-states/add`, values);
    return res || res === "" ? res : null;
  }
);

export const editPoState = createAsyncThunk(
  `${sliceName}/editPoState`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/po-states/update/${values.id}`,
      values
    );
    return res || res === "" ? values : null;
  }
);

// po priceinfo state

export const getPoPriceInfoStateMetaData = createAsyncThunk(
  `${sliceName}/getPoPriceInfoStateMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/po-states/getMetadata`)
);

export const getPoPriceInfoStateRowData = createAsyncThunk(
  `${sliceName}/gePoPriceInfoStateRowData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/po-price-info-states/getAll?${params || ""}`)
);

export const deletePoPriceInfoState = createAsyncThunk(
  `${sliceName}/deletePoPriceInfoState`,
  async ({ id }) => await fetchWrapper.post(`${baseUrl}/po-price-info-states/delete/${id}`)
);

export const getPoPriceInfoStateById = createAsyncThunk(
  `${sliceName}/getPoPriceInfoStateById`,
  async (id) => await fetchWrapper.get(`${baseUrl}/po-price-info-states/get/${id}`)
);

export const addPoPriceInfoState = createAsyncThunk(
  `${sliceName}/addPoPriceInfoState`,
  async (values) => {
    const res = await fetchWrapper.post(`${baseUrl}/po-price-info-states/create`, values);
    return res || res === "" ? res : null;
  }
);

export const editPoPriceInfoState = createAsyncThunk(
  `${sliceName}/editPoPriceInfoState`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/po-price-info-states/update/${values.id}`,
      values
    );
    return res || res === "" ? values : null;
  }
);


// po line item state
export const getPoLineItemStateMetaData = createAsyncThunk(
  `${sliceName}/getPoLineItemStateMetaData`,
  async () =>
    await fetchWrapper.get(`${baseUrl}/po-line-item-states/getMetadata`)
);

export const gePoLineItemStateRowData = createAsyncThunk(
  `${sliceName}/gePoLineItemStateRowData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/po-line-item-states/getAll?${params || ""}`)
);

export const deletePoLineItemState = createAsyncThunk(
  `${sliceName}/deletePoLineItemState`,
  async ({ id }) =>
    await fetchWrapper.post(`${baseUrl}/po-line-item-states/delete/${id}`)
);

export const getPoLineItemStateById = createAsyncThunk(
  `${sliceName}/getPoLineItemStateById`,
  async (id) =>
    await fetchWrapper.get(`${baseUrl}/po-line-item-states/get/${id}`)
);

export const addPoLineItemState = createAsyncThunk(
  `${sliceName}/addPoLineItemState`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/po-line-item-states/add`,
      values
    );
    return res || res === "" ? res : null;
  }
);

export const editPoLineItemState = createAsyncThunk(
  `${sliceName}/editPoLineItemState`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/po-line-item-states/update/${values.id}`,
      values
    );
    return res || res === "" ? values : null;
  }
);

// grn state
export const getGrnStateMetaData = createAsyncThunk(
  `${sliceName}/getGrnStateMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/grn-states/getMetadata`)
);

export const geGrnStateRowData = createAsyncThunk(
  `${sliceName}/geGrnStateRowData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/grn-states/getAll?${params || ""}`)
);

export const deleteGrnState = createAsyncThunk(
  `${sliceName}/deleteGrnState`,
  async ({ id }) =>
    await fetchWrapper.post(`${baseUrl}/grn-states/delete/${id}`)
);

export const getGrnStateById = createAsyncThunk(
  `${sliceName}/getGrnStateById`,
  async (id) => await fetchWrapper.get(`${baseUrl}/grn-states/get/${id}`)
);

export const addGrnState = createAsyncThunk(
  `${sliceName}/addGrnState`,
  async (values) => {
    const res = await fetchWrapper.post(`${baseUrl}/grn-states/add`, values);
    return res || res === "" ? res : null;
  }
);

export const editGrnState = createAsyncThunk(
  `${sliceName}/editGrnState`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/grn-states/update/${values.id}`,
      values
    );
    return res || res === "" ? values : null;
  }
);

// grn line item state
export const getGrnLineItemStateMetaData = createAsyncThunk(
  `${sliceName}/getGrnLineItemStateMetaData`,
  async () =>
    await fetchWrapper.get(`${baseUrl}/grn-line-item-states/getMetadata`)
);

export const geGrnLineItemStateRowData = createAsyncThunk(
  `${sliceName}/geGrnLineItemStateRowData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/grn-line-item-states/getAll?${params || ""}`)
);

export const deleteGrnLineItemState = createAsyncThunk(
  `${sliceName}/deleteGrnLineItemState`,
  async ({ id }) =>
    await fetchWrapper.post(`${baseUrl}/grn-line-item-states/delete/${id}`)
);

export const getGrnLineItemStateById = createAsyncThunk(
  `${sliceName}/getGrnLineItemStateById`,
  async (id) =>
    await fetchWrapper.get(`${baseUrl}/grn-line-item-states/get/${id}`)
);

export const addGrnLineItemState = createAsyncThunk(
  `${sliceName}/addGrnLineItemState`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/grn-line-item-states/add`,
      values
    );
    return res || res === "" ? res : null;
  }
);

export const editGrnLineItemState = createAsyncThunk(
  `${sliceName}/editGrnLineItemState`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/grn-line-item-states/update/${values.id}`,
      values
    );
    return res || res === "" ? values : null;
  }
);

// state
export const getOrderLineItemDemandStateMetaData = createAsyncThunk(
  `${sliceName}/getOrderLineItemDemandStateMetaData`,
  async () =>
    await fetchWrapper.get(
      `${baseUrl}/order-line-item-demand-states/getMetadata`
    )
);

export const geOrderLineItemDemandStateRowData = createAsyncThunk(
  `${sliceName}/geOrderLineItemDemandStateRowData`,
  async (params) =>
    await fetchWrapper.get(
      `${baseUrl}/order-line-item-demand-states/getAll?${params || ""}`
    )
);

export const deleteOrderLineItemDemandState = createAsyncThunk(
  `${sliceName}/deleteOrderLineItemDemandState`,
  async ({ id }) =>
    await fetchWrapper.post(
      `${baseUrl}/order-line-item-demand-states/delete/${id}`
    )
);

export const getOrderLineItemDemandStateById = createAsyncThunk(
  `${sliceName}/getOrderLineItemDemandStateById`,
  async (id) =>
    await fetchWrapper.get(`${baseUrl}/order-line-item-demand-states/get/${id}`)
);

export const addOrderLineItemDemandState = createAsyncThunk(
  `${sliceName}/addOrderLineItemDemandState`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/order-line-item-demand-states/add`,
      values
    );
    return res || res === "" ? res : null;
  }
);

export const editOrderLineItemDemandState = createAsyncThunk(
  `${sliceName}/editOrderLineItemDemandState`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/order-line-item-demand-states/update/${values.id}`,
      values
    );
    return res || res === "" ? values : null;
  }
);

// stone state
export const getOrderLineItemDemandStoneStateMetaData = createAsyncThunk(
  `${sliceName}/getOrderLineItemDemandStoneStateMetaData`,
  async () =>
    await fetchWrapper.get(
      `${baseUrl}/order-demand-line-item-stone-states/getMetadata`
    )
);

export const geOrderLineItemDemandStoneStateRowData = createAsyncThunk(
  `${sliceName}/geOrderLineItemDemandStoneStateRowData`,
  async (params) =>
    await fetchWrapper.get(
      `${baseUrl}/order-demand-line-item-stone-states/getAll?${params || ""}`
    )
);

export const deleteOrderLineItemDemandStoneState = createAsyncThunk(
  `${sliceName}/deleteOrderLineItemDemandStoneState`,
  async ({ id }) =>
    await fetchWrapper.post(
      `${baseUrl}/order-demand-line-item-stone-states/delete/${id}`
    )
);

export const getOrderLineItemDemandStoneStateById = createAsyncThunk(
  `${sliceName}/getOrderLineItemDemandStoneStateById`,
  async (id) =>
    await fetchWrapper.get(
      `${baseUrl}/order-demand-line-item-stone-states/get/${id}`
    )
);

export const addOrderLineItemDemandStoneState = createAsyncThunk(
  `${sliceName}/addOrderLineItemDemandStoneState`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/order-demand-line-item-stone-states/add`,
      values
    );
    return res || res === "" ? res : null;
  }
);

export const editOrderLineItemDemandStoneState = createAsyncThunk(
  `${sliceName}/editOrderLineItemDemandStoneState`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/order-demand-line-item-stone-states/update/${values.id}`,
      values
    );
    return res || res === "" ? values : null;
  }
);

// pr state
export const getPrStateMetaData = createAsyncThunk(
  `${sliceName}/getPrStateMetaData`,
  async () =>
    await fetchWrapper.get(`${baseUrl}/pr-internal-states/getMetadata`)
);

export const gePrStateRowData = createAsyncThunk(
  `${sliceName}/gePrStateRowData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/pr-internal-states/getAll?${params || ""}`)
);

export const deletePrState = createAsyncThunk(
  `${sliceName}/deletePrState`,
  async ({ id }) =>
    await fetchWrapper.post(`${baseUrl}/pr-internal-states/delete/${id}`)
);

export const getPrStateById = createAsyncThunk(
  `${sliceName}/getPrStateById`,
  async (id) =>
    await fetchWrapper.get(`${baseUrl}/pr-internal-states/get/${id}`)
);

export const addPrState = createAsyncThunk(
  `${sliceName}/addPrState`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/pr-internal-states/add`,
      values
    );
    return res || res === "" ? res : null;
  }
);

export const editPrState = createAsyncThunk(
  `${sliceName}/editPrState`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/pr-internal-states/update/${values.id}`,
      values
    );
    return res || res === "" ? values : null;
  }
);

// create bulk pr line item

export const addMultiPrLineItem = createAsyncThunk(
  `${sliceName}/addMultiPrLineItem`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/pr-line-item/multi/create`,
      values
    );
    return res || res === "" ? res : null;
  }
);

// replan order demand line item
export const replanOrderLineitemDemand = createAsyncThunk(
  `${sliceName}/replanOrderLineitemDemand`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/order-lineitem-demand/replan`,
      values
    );
    return res || res === "" ? res : null;
  }
);

// service charge po

// Grn
export const getServiceChargePoMetaData = createAsyncThunk(
  `${sliceName}/getServiceChargePoMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/service-charge-po/getMetadata`)
);

export const getServiceChargePoRowData = createAsyncThunk(
  `${sliceName}/getServiceChargePoRowData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/service-charge-po/getAll?${params || ""}`)
);

export const deleteServiceChargePo = createAsyncThunk(
  `${sliceName}/deleteServiceChargePo`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/service-charge-po/delete${
        deletePermanent ? "-permanent" : ""
      }/${id}`,
      { id }
    );
    return res || res === "" ? { id } : null;
  }
);

export const addServiceChargePo = createAsyncThunk(
  `${sliceName}/addServiceChargePo`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/service-charge-po/create`,
      values
    );
    return res || res === "" ? res : null;
  }
);

export const editServiceChargePo = createAsyncThunk(
  `${sliceName}/editServiceChargePo`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/service-charge-po/edit/${values.id}`,
      values
    );
    return res || res === "" ? values : null;
  }
);

export const getServiceChargePoById = createAsyncThunk(
  `${sliceName}/getServiceChargePoById`,
  async (id) => await fetchWrapper.get(`${baseUrl}/service-charge-po/get/${id}`)
);

export const getServiceChargePoRowDataById = createAsyncThunk(
  `${sliceName}/getServiceChargePoRowDataById`,
  async ({ params, id }) =>
    await fetchWrapper.get(
      `${baseUrl}/service-charge-po/version-history/get/${id}?${params || ""}`
    )
);

export const mergePRLineItem = createAsyncThunk(
  `${sliceName}/mergePRLineItem`,
  async ({ childPrLineItemId, parentPrLineItemId }) =>
    await fetchWrapper.post(
      `${baseUrl}/pr-line-item/${childPrLineItemId}/merge/${parentPrLineItemId}`
    )
);

// grn qc state
export const getGrnQcStateMetaData = createAsyncThunk(
  `${sliceName}/getGrnQcStateMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/grn-qc-states/getMetadata`)
);

export const geGrnQcStateRowData = createAsyncThunk(
  `${sliceName}/geGrnQcStateRowData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/grn-qc-states/getAll?${params || ""}`)
);

export const getGrnQcStateVersionMetaData = createAsyncThunk(
  `${sliceName}/getGrnQcStateVersionMetaData`,
  async (params) =>
    await fetchWrapper.get(
      `${baseUrl}/grn-qc-states/getMetadata?versioning=true&${params || ""}`
    )
);

export const getGrnQcStateVersionRowDataById = createAsyncThunk(
  `${sliceName}/getGrnQcStateVersionRowDataById`,
  async ({ params, id }) =>
    await fetchWrapper.get(
      `${baseUrl}/grn-qc-states/version-history/get/${id}?${params || ""}`
    )
);

export const deleteGrnQcState = createAsyncThunk(
  `${sliceName}/deleteGrnQcState`,
  async ({ id, deletePermanent }) =>
    await fetchWrapper.delete(
      `${baseUrl}/grn-qc-states/delete${
        deletePermanent ? "-permanent" : ""
      }/${id}`
    )
);

export const getGrnQcStateById = createAsyncThunk(
  `${sliceName}/getGrnQcStateById`,
  async (id) => await fetchWrapper.get(`${baseUrl}/grn-qc-states/get/${id}`)
);

export const addGrnQcState = createAsyncThunk(
  `${sliceName}/addGrnQcState`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/grn-qc-states/create`,
      values
    );
    return res || res === "" ? res : null;
  }
);

export const editGrnQcState = createAsyncThunk(
  `${sliceName}/editGrnQcState`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/grn-qc-states/edit/${values.id}`,
      values
    );
    return res || res === "" ? values : null;
  }
);

// currency calculator
export const getCurrencyHedgesList = createAsyncThunk(
  `${sliceName}/getCurrencyHedgesList`,
  async () => await fetchWrapper.get(`${baseUrl}/currency-rates/getAll`)
);

export const getCurrencyHedgesCurrentRate = createAsyncThunk(
  `${sliceName}/getCurrencyHedgesCurrentRate`,
  async ({ conversionValue, totalAmount }) =>
    await fetchWrapper.get(
      `${baseUrl}/currency-rates/getConvertedRate/${conversionValue}/${totalAmount}`
    )
);

export const getCurrencyHedgesConvertedRate = createAsyncThunk(
  `${sliceName}/getCurrencyHedgesConvertedRate`,
  async ({ fromCurrencyCode, toCurrencyCode, invoiceTotalAmount }) =>
    await fetchWrapper.get(
      `${baseUrl}/currency-hedges/getConvertedRate/${fromCurrencyCode}/${toCurrencyCode}/${invoiceTotalAmount}`
    )
);

export const getActivityCalendarVersionHistory = createAsyncThunk(
  `${sliceName}/getActivityCalendarVersionHistory`,
  async (id) =>
    await fetchWrapper.get(
      `${baseUrl}/activity-calendars/version-history/get/${id}`
    )
);

export const getPlanDateMetadata = createAsyncThunk(
  `${sliceName}/getPlanDateMetadata`,
  async (id) =>
    await fetchWrapper.get(
      `${baseUrl}/order-lineitem-demand/plandates/getMetadata`
    )
);

export const editPlanDate = createAsyncThunk(
  `${sliceName}/editPlanDate`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/order-lineitem-demand/plan-dates/update/${values.id}`,
      values
    );
    return res || res === "" ? values : null;
  }
);

export const updateInventoryTag = createAsyncThunk(
  `${sliceName}/updateInventoryTag`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/inventory-tag/update`,
      values
    );
    return res || res === "" ? res : null;
  }
);

export const orderLineItemDemandAddFullProduct = createAsyncThunk(
  `${sliceName}/orderLineItemDemandAddFullProduct`,
  async ({stockTagId, orderLineItemDemandId}) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/order-lineitem-demand/add/fullproduct?orderLineItemDemandId=${orderLineItemDemandId}&stockTagId=${stockTagId}`,
    );
    return res || res === "" ? res : null;
  }
);

export const orderLineItemDemandRemoveFullProduct = createAsyncThunk(
  `${sliceName}/orderLineItemDemandRemoveFullProduct`,
  async ({stockTagId, fullproductId}) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/order-lineitem-demand/remove/fullproduct?fullproductId=${fullproductId}&stockTagId=${stockTagId}`,
    );
    return res || res === "" ? res : null;
  }
);


export const getDemandTypeList = createAsyncThunk(
  `${sliceName}/getDemandTypeList`,
  async (id) => await fetchWrapper.get(`${baseUrl}/demand/metadata`)
);

export const getDemandTypeMetaData = createAsyncThunk(
  `${sliceName}/getDemandTypeMetaData`,
  async (url) => await fetchWrapper.get(`${baseUrl}${url}`)
);

export const getDemandTypeRowData = createAsyncThunk(
  `${sliceName}/getDemandTypeRowData`,
  async ({ url, params }) =>
    await fetchWrapper.get(`${baseUrl}${url}?${params || ""}`)
);

export const getOrderDemandLineItemsById = createAsyncThunk(
  `${sliceName}/getOrderDemandLineItemsById`,
  async (url) => await fetchWrapper.get(`${baseUrl}${url}`)
);

export const getOrderDemandLineItemVersionHistory = createAsyncThunk(
  `${sliceName}/getOrderDemandLineItemVersionHistory`,
  async ({ url, params }) =>
    await fetchWrapper.get(`${baseUrl}${url}?${params || ""}`)
);

export const deleteDemandTypeById = createAsyncThunk(
  `${sliceName}/deleteDemandTypeById`,
  async (url) => await fetchWrapper.post(`${baseUrl}${url}`)
);
export const getPurchaseReturnMetaData = createAsyncThunk(
  `${sliceName}/getPurchaseReturnMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/purchase-returns/getMetadata`)
);

export const getPurchaseReturnRowData = createAsyncThunk(
  `${sliceName}/getPurchaseReturnRowData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/purchase-returns/getAll?${params || ""}`)
);

export const getPurchaseReturnById = createAsyncThunk(
  `${sliceName}/getPurchaseReturnById`,
  async (id) => await fetchWrapper.get(`${baseUrl}/purchase-returns/get/${id}`)
);

export const deletePurchaseReturn = createAsyncThunk(
  `${sliceName}/deletePurchaseReturn`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/purchase-returns/delete/${id}`,
      { id }
    );
    return res || res === "" ? { id } : null;
  }
);

export const addPurchaseReturns = createAsyncThunk(
  `${sliceName}/addPurchaseReturns`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/purchase-returns/create`,
      values
    );
    return res || res === "" ? res : null;
  }
);

export const editPurchaseReturns = createAsyncThunk(
  `${sliceName}/editPurchaseReturns`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/purchase-returns/update/${values.id}`,
      values
    );
    return res || res === "" ? values : null;
  }
);

export const downloadPrlineItemExcel = createAsyncThunk(
  `${sliceName}/downloadPrlineItemExcel`,
  async (id) =>
    await fetchWrapper.get(`${baseUrl}/pr-line-item/get/${id}/downloadExcel`)
);

export const downloadPrlineItemPDF = createAsyncThunk(
  `${sliceName}/downloadPrlineItemPDF`,
  async (id) =>
    await fetchWrapper.get(`${baseUrl}/pr-line-item/get/${id}/downloadPdf`)
);

export const downloadPoExcel = createAsyncThunk(
  `${sliceName}/downloadPoExcel`,
  async (id) => await fetchWrapper.get(`${baseUrl}/po/get/${id}/downloadExcel`)
);

export const downloadPoPDF = createAsyncThunk(
  `${sliceName}/downloadPoPDF`,
  async (id) => await fetchWrapper.get(`${baseUrl}/po/get/${id}/downloadPdf`)
);

export const downloadGrnExcel = createAsyncThunk(
  `${sliceName}/downloadGrnExcel`,
  async (id) => await fetchWrapper.get(`${baseUrl}/grn/get/${id}/downloadExcel`)
);

export const downloadGrnPDF = createAsyncThunk(
  `${sliceName}/downloadGrnPDF`,
  async (id) => await fetchWrapper.get(`${baseUrl}/grn/get/${id}/downloadPdf`)
);


export const getOrderDemandLineItemMetalMetaData = createAsyncThunk(
  `${sliceName}/getOrderDemandLineItemMetalMetaData`,
  async () =>
    await fetchWrapper.get(
      `${baseUrl}/order-demand-line-item-metal/getMetadata`
    )
);

export const getOrderDemandLineItemMetalRowData = createAsyncThunk(
  `${sliceName}/getOrderDemandLineItemMetalRowData`,
  async (params) =>
    await fetchWrapper.get(
      `${baseUrl}/order-demand-line-item-metal/getAll?${params || ""}`
    )
);

export const getOrderDemandLineItemFindingsMetaData = createAsyncThunk(
  `${sliceName}/getOrderDemandLineItemFindingsMetaData`,
  async () =>
    await fetchWrapper.get(
      `${baseUrl}/order-demand-line-item-findings/getMetadata`
    )
);

export const getOrderDemandLineItemFindingsRowData = createAsyncThunk(
  `${sliceName}/getOrderDemandLineItemFindingsRowData`,
  async (params) =>
    await fetchWrapper.get(
      `${baseUrl}/order-demand-line-item-findings/getAll?${params || ""}`
    )
);

export const getOrderDemandLineItemAddonMetaData = createAsyncThunk(
  `${sliceName}/getOrderDemandLineItemAddonMetaData`,
  async () =>
    await fetchWrapper.get(
      `${baseUrl}/order-line-item-demand-addons/getMetadata`
    )
);

export const getOrderDemandLineItemAddonRowData = createAsyncThunk(
  `${sliceName}/getOrderDemandLineItemAddonRowData`,
  async (params) =>
    await fetchWrapper.get(
      `${baseUrl}/order-line-item-demand-addons/getAll?${params || ""}`
    )
);

export const getOrderDemandLineItemFullProductMetaData = createAsyncThunk(
  `${sliceName}/getOrderDemandLineItemFullProductMetaData`,
  async () =>
    await fetchWrapper.get(
      `${baseUrl}/order-line-item-demand-full-products/getMetadata`
    )
);

export const getOrderDemandLineItemFullProductRowData = createAsyncThunk(
  `${sliceName}/getOrderDemandLineItemFullProductRowData`,
  async (params) =>
    await fetchWrapper.get(
      `${baseUrl}/order-line-item-demand-full-products/getAll?${params || ""}`
    )
);

export const splitPoLineItem = createAsyncThunk(
  `${sliceName}/splitPoLineItem`,
  async ({values, id}) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/po-line-item/${id}/split`,
      values
    );
    return res || res === "" ? res : null;
  }
);

export const splitPrLineItem = createAsyncThunk(
  `${sliceName}/splitPrLineItem`,
  async ({values, id}) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/pr-line-item/${id}/split`,
      values
    );
    return res || res === "" ? res : null;
  }
);

export const mergePOLineItem = createAsyncThunk(
  `${sliceName}/mergePOLineItem`,
  async ({ childPrLineItemId, parentPrLineItemId }) =>
    await fetchWrapper.post(
      `${baseUrl}/po-line-item/${childPrLineItemId}/merge/${parentPrLineItemId}`
    )
);

// PoLineItemDetail
export const getPoLineItemDetailMetaData = createAsyncThunk(
  `${sliceName}/getPoLineItemDetailMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/po-line-item-detail/getMetadata`)
);

export const getPoLineItemDetailRowData = createAsyncThunk(
  `${sliceName}/getPoLineItemDetailRowData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/po-line-item-detail/getAll?${params || ""}`)
);

export const deletePoLineItemDetail = createAsyncThunk(
  `${sliceName}/deletePoLineItemDetail`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/po-line-item-detail/delete${
        deletePermanent ? "-permanent" : ""
      }/${id}`,
      { id }
    );
    return res || res === "" ? { id } : null;
  }
);

export const addPoLineItemDetail = createAsyncThunk(
  `${sliceName}/addPoLineItemDetail`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/po-line-item-detail/create`,
      values
    );
    return res || res === "" ? res : null;
  }
);

export const editPoLineItemDetail = createAsyncThunk(
  `${sliceName}/editPoLineItemDetail`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/po-line-item-detail/edit/${values.id}`,
      values
    );
    return res || res === "" ? values : null;
  }
);

export const getPoLineItemDetailById = createAsyncThunk(
  `${sliceName}/getPoLineItemDetailById`,
  async (id) => await fetchWrapper.get(`${baseUrl}/po-line-item-detail/get/${id}`)
);

export const deletePOLineItemDetailById = createAsyncThunk(
  `${sliceName}/deletePOLineItemDetailById`,
  async ({ id }) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/po-line-item-detail/delete/${id}`,
      {
        id,
      }
    );
    return res;
  }
);

export const getPoLineItemDetailVersionMetaData = createAsyncThunk(
  `${sliceName}/getPoLineItemDetailVersionMetaData`,
  async (params) =>
    await fetchWrapper.get(
      `${baseUrl}/po-line-item-detail/getMetadata?versioning=true&${params || ""}`
    )
);

export const getPoLineItemDetailVersionRowDataById = createAsyncThunk(
  `${sliceName}/getPoLineItemDetailVersionRowDataById`,
  async ({ params, id }) =>
    await fetchWrapper.get(
      `${baseUrl}/po-line-item-detail/version-history/get/${id}?${params || ""}`
    )
);

export const findBetterStones = createAsyncThunk(
  `${sliceName}/findBetterStones`,
  async (values) => {
    const res = await fetchWrapper.post(`${baseUrl}/order-demand-line-item-stones/findBetterStones`, values);
    return res || res === "" ? res : null;
  }
);

//Order status
export const getOrderStatusMetaData = createAsyncThunk(
  `${sliceName}/getOrderStatusMetaData`,
  async () =>
    await fetchWrapper.get(`${baseUrl}/order/order-line-item/status/getMetadata`)
);

export const getOrderStatusRowData = createAsyncThunk(
  `${sliceName}/getOrderStatusRowData`,
  async (id) =>
    await fetchWrapper.get(`${baseUrl}/order/${id}/order-line-item/status/get`)
);


export const createInventoryReplica = createAsyncThunk(
  `${sliceName}/createInventoryReplica`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/order-demand-line-item-stones/create-inventory-replica`,
      values
    );
    return res || res === "" ? values : null;
  }
);