/* eslint-disable no-useless-escape */
import { orderBy } from "lodash";
import { store } from "../_store";
import { fetchWrapper } from "./fetch-wrapper";

const qs = require("qs");
const baseUrl = process.env.REACT_APP_API_URL;

export const getTranslationLabel = (id) => {
  const { translations } = store.getState();
  return translations
    .find((item) => item.translate_id === id)
    ?.code.toUpperCase();
};

export const formattedErrorResponse = (error) => {
  let errorMsg =
    error?.message ||
    "Some unexpected error occured. Kindly try again in sometime.";
  return { error: errorMsg };
};

export const getActionName = (action) => {
  let actionName = "";
  switch (action) {
    case "edit":
      actionName = "Edit";
      break;
    case "view":
      actionName = "View";
      break;
    case "custom":
      break;
    default:
      actionName = "Add";
  }
  return actionName;
};

export const checkAndAddOrRemove = (array, value) => {
  if (array.includes(value)) {
    // Value already exists in array, so remove it
    array.splice(array.indexOf(value), 1);
  } else {
    // Value does not exist in array, so add it
    array.push(value);
  }
  return array;
};
export const toggleItemInArray = (arr, item) => {
  const newArr = [...arr];
  const index = newArr.indexOf(item);
  if (index > -1) {
    newArr?.splice(index, 1);
  } else {
    newArr?.push(item);
  }
  return newArr;
};

// Find objects without Keys
export const objectWithoutKey = (object, key) => {
  const { ...otherKeys } = object;
  return otherKeys;
};

// Nested Recursive Function to get Datas based on KeyMapping and DataKeys

export const ReadNestedValues = (obj, mapping, datakeys, translatable) => {
  const { translations } = store.getState();

  if (mapping.length === 0) {
    //if further mapping is not avaiaible, then look for data as per datakeys and return values accordingly
    let finalValue;

    //if trnslateid.. we will print en / us etc based on the transaltions data available with us
    if (obj && Object.hasOwn(obj, "translateId") && translatable) {
      // check for translate Id
      // console.log("TRANSLATE ID: ", obj.translateId);
      // check if the translate Id of the current object is present in translations,
      const result = translations?.find(
        (translation) => translation.translate_id === obj.translateId
      );
      //if translate Id exists, return the translate name in filan value
      if (result) {
        finalValue = { [obj.translateId]: datakeys.map((item) => obj[item]) }; //.join(",")
        // console.log("finalValue 1", finalValue, result);
      } else {
        console.log("ID not found.");
      }
    }

    //if not trnslateid, the read directly
    else {
      finalValue =
        obj && datakeys ? datakeys?.map((item) => obj[item])?.join() : "-";
    }
    return finalValue;
  } else {
    //check if further mapping is avaiaible, then trigger recusrive method based on object type
    const { key } = mapping?.[0] || null;
    const nextObj = obj?.[key];
    const remainingMapping = mapping.slice(1);
    if (nextObj) {
      if (Array.isArray(nextObj)) {
        return nextObj.map((element) =>
          ReadNestedValues(element, remainingMapping, datakeys, translatable)
        );
      } else {
        return (
          ReadNestedValues(nextObj, remainingMapping, datakeys, translatable) ||
          []
        );
      }
    } else {
      return "";
    }
  }
};
// Get Nested Columns Mapping
export const getColumnsMappings = (columnData, columnsMappings) => {
  let updatedObject = {};
  columnsMappings.forEach((col) => {
    if (col.keyName) {
      Object.assign(updatedObject, {
        [col.keyName]: columnData[col.keyName],
      });

      // if key maaping / data key is available, we will loop data accrodingly
      if (Object.keys(col.keymapping)?.length && col.datakey?.length) {
        // generating columns mapping arrays
        let keyMappingOrder = [];
        for (const [key, value] of Object.entries(col.keymapping)) {
          keyMappingOrder.push({ key: key, ...value });
        }
        // columns mapping arrays ordering based on the heirarchy using lodash
        keyMappingOrder = orderBy(keyMappingOrder, "heirarchy", "asc");

        // final recursive method to lop / iterate through all the nested mappings as per keymapping order and data kyes
        if (updatedObject?.[col.keyName]) {
          const data = ReadNestedValues(
            columnData,
            keyMappingOrder,
            col.datakey,
            col.translatable
          );

          const newData = col.translatable
            ? { translatable: data }
            : Array.isArray(data)
            ? data?.join(",")
            : data;

          Object.assign(updatedObject?.[col.keyName], { data: newData });
        }
      }
      // if key maaping / data key not available, we will print directly
      else {
        if (updatedObject?.[col.keyName]) {
          Object.assign(updatedObject?.[col.keyName], {
            data: columnData[col.keyName],
          });
        } else {
          Object.assign(updatedObject, {
            [col.keyName]: { data: columnData[col.keyName] },
          });
        }
      }
    }
  });
  return updatedObject;
};

export const generateUpdateApiQueryParams = (state) => {
  return qs.stringify(state, { arrayFormat: "brackets" });
};

export const tableApiTrigger = async (
  pageSize,
  pageIndex,
  _pageCount,
  _filters,
  _sortBy,
  _endpoint
) => {
  // ! api endpoint should be dynamic as well based on the params passed to the table api trigger function
  const res = await fetchWrapper.get(
    `${baseUrl}/metal/metal-property/get-All?pageSize=${pageSize}&pageIndex=${pageIndex}`
  );
  return res;
};

// check if states are equal
export const isDeepEqual = (object1, object2) => {
  if (object1 && object2) {
    const objKeys1 = Object.keys(object1);
    const objKeys2 = Object.keys(object2);

    if (objKeys1.length !== objKeys2.length) return false;

    for (const key of objKeys1) {
      const value1 = object1[key];
      const value2 = object2[key];

      const isObjects = isObject(value1) && isObject(value2);

      if (
        (isObjects && !isDeepEqual(value1, value2)) ||
        (!isObjects && value1 !== value2)
      ) {
        return false;
      }
    }
    return true;
  } else {
    return false;
  }
};

export const isObject = (object) => {
  return object != null && typeof object === "object";
};

export const upsert = (array, element, key) => {
  const rawArray = array || [];
  const hasEl = rawArray.some((i) => i.hasOwnProperty(key));
  if (hasEl) {
    return rawArray.map((i) => {
      if (i.hasOwnProperty(key)) {
        return {
          ...i,
          ...element,
        };
      }
      return i;
    });
  }

  rawArray.push(element);
  return rawArray;
};

export const decimalCount = (num) => {
  // Convert to String
  const numStr = String(num);
  // String Contains Decimal
  if (numStr.includes(".")) {
    return numStr.split(".")[1].length;
  }
  // String Does Not Contain Decimal
  return 0;
};

export const getTranslationColumnGrid = (size = 1) => {
  const { commonData } = store.getState();
  let translationCount = commonData?.fieldSelectedLanguages?.forms?.length ?? 1;
  let selectedstranslation = translationCount < 4 ? 4 : translationCount;
  let gridCount = translationCount > 4 ? 3 : 12 / selectedstranslation;
  return `col-xl-${gridCount * size} col-md-${size * gridCount + 1}`;
};

export const toTitleCase = (str) => {
  if (!str) {
    return null;
  } else {
    return str?.replace(/\w\S*/g, function (txt) {
      return txt?.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
};

export const getNumberHint = (value) => {
  return `Minimum Value: ${
    value?.min || value?.minQty || "N/A"
  }, Maximum Value: ${value?.max || value?.maxQty || "N/A"} and Precision: ${
    value?.precision || "N/A"
  }`;
};

export const getCalculativeHint = (value) => {
  return `Calculated: ${value?.name} + ${value?.calcValue}`;
};

export const getQtyHint = (value) => {
  return `Minimum Value: ${value?.minQty || "N/A"}, Maximum Value: ${
    value?.maxQty || "N/A"
  }`;
};

export const getTextHint = (value) => {
  return `Minimum Length: ${value?.min || "N/A"}, Maximum Length: ${
    value?.max || "N/A"
  }`;
};

export const uniqueMetaDataColumns = (metaData) => {
  let rawMetData = metaData;
  let result = [];
  let finalResult = [];
  const unique = [
    ...new Set(metaData?.columns?.filter((i) => i).map((item) => item.keyName)),
  ];
  unique.forEach((element) => {
    let column = metaData?.columns.filter(
      (item) => item && item.keyName === element
    );
    if (column?.length > 0) {
      result.push(column[column?.length - 1]);
    }
  });
  const uniqueResult = [...new Set(result.map((item) => item.id))];
  uniqueResult.forEach((element) => {
    let column = result.filter((item) => item.id === element);
    if (column?.length > 0) {
      finalResult.push(column[column?.length - 1]);
    }
  });
  rawMetData.columns = finalResult;
  return rawMetData;
};

export const onValueChangeLengthValuesV2 = (
  e,
  itemvalue,
  key,
  setFieldValue
) => {
  var rgx = /^[0-9]*\.?[0-9]*$/;
  if (e?.target?.value.match(rgx)) {
    const decimalLength = decimalCount(e.target.value);
    if (!Number.isInteger(Number(e.target.value))) {
      const precisionLimit =
        itemvalue?.precision > 100 ? 100 : itemvalue?.precision;
      const decimalLengthLimit = decimalLength > 100 ? 100 : decimalLength;

      const fieldValue = Number(e.target.value).toFixed(
        precisionLimit > decimalLengthLimit
          ? precisionLimit
          : decimalLengthLimit ?? 0
      );
      e.target.value = fieldValue;
      setFieldValue(key, fieldValue);
    }
    if (Number(itemvalue?.min) && e.target.value < Number(itemvalue?.min)) {
      e.target.value = itemvalue?.min;
      setFieldValue(key, itemvalue?.min);
    } else {
      setFieldValue(key, e.target.value);
    }
    if (Number(itemvalue?.max) && e.target.value > Number(itemvalue?.max)) {
      e.target.value = itemvalue?.max;
      setFieldValue(key, itemvalue?.max);
    } else {
      setFieldValue(key, e.target.value);
    }
  }
};

export const onValueChangeLengthValuesText = (
  e,
  itemvalue,
  key,
  setFieldValue
) => {
  if (
    Number(itemvalue?.min) > Number(itemvalue?.max) ||
    Number(itemvalue?.max) === 0 ||
    e.target.value === "" ||
    e.target.value === null
  ) {
    return;
  }
  if (
    Number(itemvalue?.min) &&
    e.target.value?.length < Number(itemvalue?.min)
  ) {
    e.target.value = e.target.value.substring(0, itemvalue?.min);
    setFieldValue(key, e.target.value.substring(0, ""));
  }
  if (
    Number(itemvalue?.max) &&
    e.target.value?.length > Number(itemvalue?.max)
  ) {
    e.target.value = e.target.value.substring(0, itemvalue?.max);
    setFieldValue(key, e.target.value.substring(0, itemvalue?.max));
  }
};
export const onValueChangeLengthValues = ({
  value,
  itemvalue,
  setFieldValue,
  fieldName
}) => {
  const decimalLength = decimalCount(value);
  if (
    Number(itemvalue?.min) > Number(itemvalue?.max) ||
    Number(itemvalue?.max) === 0 ||
    value === "" || value !== 0 ||
    decimalLength === null
  ) {
    return;
  }
  if (!Number.isInteger(Number(value))) {
    const fieldValue = Number(value).toFixed(
      itemvalue?.precision > 100
        ? decimalLength > 100
          ? 100
          : decimalLength
        : decimalLength > itemvalue?.precision
        ? itemvalue?.precision
        : decimalLength || 0
    );
    setFieldValue(
      fieldName,
      fieldValue
    );
    return;
  }
  if (Number(itemvalue?.min) && value < Number(itemvalue?.min)) {
    setFieldValue(
      fieldName,
      itemvalue?.min
    );
    return;
  }
  if (Number(itemvalue?.max) && value > Number(itemvalue?.max)) {
    setFieldValue(
      fieldName,
      itemvalue?.max
    );
  }
};

export const validateNumberFields = (values, type, touched) => {
  if (!touched) {
    return false;
  }
  const validation = values.some((obj) => {
    const value = obj[type];
    return value !== undefined && value !== "" && !isNaN(value) && Number.isInteger(Number(value));
    // Ensure value is a valid integer
  });
  return !validation;
};

export const formatPrice = (price = 0) => {
  const [decimal] = price.toString().split(".");
 
  if (decimal) {
    let roundedPrice = parseFloat(price).toFixed(2);
    if (roundedPrice.endsWith(".00")) {
      return roundedPrice.split(".")[0];
    }
    if (roundedPrice.endsWith("0")) {
      return parseFloat(price).toFixed(1);
    }
 
    return roundedPrice;
  }
 
  return price;
};

export const uuidv4 = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
};

export const getPageSize = (pagePath) => {
  let pageSizeValue = localStorage.getItem("pageSize");
  pageSizeValue = Number(pageSizeValue);
  let localParmas = localStorage.getItem("params");
  localParmas = JSON.parse(localParmas);

  if (pageSizeValue > 0) {
    return `pageSize=${pageSizeValue}&pageIndex=${
      localParmas?.pagePath === pagePath ? localParmas?.pageIndex ?? 0 : 0
    }`;
  }
  localStorage.setItem("pageSize", 10);
  return "pageSize=10&pageIndex=0";
};

export const sortProperties = (data = []) => {
  data.sort((a, b) => {
    if (a.sequence === 0 && b.sequence !== 0) {
      return 1;
    } else if (a.sequence !== 0 && b.sequence === 0) {
      return -1;
    } else {
      return a.sequence - b.sequence;
    }
  });
  return data;
};
export const handleTableExistingSatate = (data) => {
  const { orderColumns, queryParams, hiddenColumns } = data ?? {};
  if (orderColumns && Array.isArray(orderColumns)) {
    localStorage.setItem(
      `columnOrder-${data?.tableId}`,
      JSON.stringify(orderColumns)
    );
  }
  if (hiddenColumns && Array.isArray(hiddenColumns)) {
    localStorage.setItem(
      `hiddenColumns-${data?.tableId}`,
      JSON.stringify(hiddenColumns)
    );
  }
  return queryParams ? `&${queryParams}` : "";
};

export const parseQueryString = (queryParams) => {
  return qs.parse(queryParams, {
    arrayFormat: "brackets",
  });
};

export const checkOnlyNumber = (event) => {
  const allowedCharacters = /^[0-9\.]$/;
  const key = event.key;
  const inputField = event.target;
  const currentValue = inputField.value;
  const cursorPosition = inputField.selectionStart;

  if (!allowedCharacters.test(key)) {
    event.preventDefault();
    return;
  }

  if (currentValue === "" && key === ".") {
    inputField.value = "0.";
    event.preventDefault();
    return;
  }

  if (key === "." && currentValue.indexOf(".") !== -1) {
    event.preventDefault();
    return;
  }

  if (
    key === "." &&
    cursorPosition !== currentValue.length &&
    currentValue.includes(".")
  ) {
    event.preventDefault();
    return;
  }
};

export const formateRowDataPagination = ({
  data,
  tableOldState,
  isDeleted = false,
}) => {
  let rawRowData = data;
  rawRowData.pageCount = rawRowData?.pageCount ? rawRowData.pageCount : 1;
  rawRowData.pageIndex = rawRowData?.pageIndex ? rawRowData.pageIndex : 0;
  rawRowData.pageSize = rawRowData?.pageSize !== 0 ? rawRowData.pageSize : 10;
  rawRowData.filters =
    (isDeleted ? null : tableOldState?.searchKey?.relations) ??
    ((rawRowData?.filters !== 0 ? rawRowData.filters : []) || []);
  rawRowData.sortBy = tableOldState?.sortBy?.relations
    ? tableOldState?.sortBy?.relations
    : [];

  return rawRowData;
};
