import { createAsyncThunk } from "@reduxjs/toolkit";

import { fetchWrapper, generateUpdateApiQueryParams } from "_utils";

// create slice
const baseUrl = process.env.REACT_APP_API_URL;
const sliceName = "positionData";

// APIs
/*Postion Units */
/* table Meta Data */

// Position
export const getPositionsMetaData = createAsyncThunk(
  `${sliceName}/getPositionsMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/position/getMetadata`)
);

export const getPositionsRowData = createAsyncThunk(
  `${sliceName}/getPositionsRowData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/position/getAll?${params || ""}`)
);

export const getPositionsList = createAsyncThunk(
  `${sliceName}/getPositionsList`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/position/dropdown/getAll?${params || ""}`)
);


export const deletePosition = createAsyncThunk(
  `${sliceName}/deletePosition`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(`${baseUrl}/position/delete${deletePermanent ? '-permanent' : ''}/${id}`, { id });
    return res || res === "" ? { id } : null;
  }
);

export const getPositionById = createAsyncThunk(
  `${sliceName}/getPositionById`,
  async (apiParams) => {
    let id = apiParams?.id
    let params = undefined
    if (!id) {
      id = apiParams
    } else {
      params = generateUpdateApiQueryParams(apiParams?.params)
    }
    return await fetchWrapper.get(
      `${baseUrl}/position/get/${id}?${params ? `${params || ""}` : ''}`
    )
  }
);

export const addPosition = createAsyncThunk(
  `${sliceName}/addPosition`,
  async (values) => {
    const res = await fetchWrapper.post(`${baseUrl}/position/add`, values);
    return res || res === "" ? res : null;
  }
);

export const getPositionRowDataByIdForVersion = createAsyncThunk(
  `${sliceName}/getPositionRowDataByIdForVersion`,
  async ({ params, id }) => await fetchWrapper.get(`${baseUrl}/position/version-history/get/${id}?${params || ""}`)
);

export const duplicatePosition = createAsyncThunk(
  `${sliceName}/duplicatePosition`,
  async ({ id }) => await fetchWrapper.post(`${baseUrl}/position/duplicate/${id}`)
);

export const getPositionMetaDataByIdForVersion = createAsyncThunk(
  `${sliceName}/getPositionMetaDataByIdForVersion`,
  async (params) => await fetchWrapper.get(`${baseUrl}/position/getMetadata?versioning=true&${params || ""}`)
);

export const getPositionData = createAsyncThunk(
  `${sliceName}/getPostionSlice`,
  async () => await fetchWrapper.get(`${baseUrl}/position?filter=`)
);
