import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchWrapper, generateUpdateApiQueryParams } from "_utils";

// create slice
const baseUrl = process.env.REACT_APP_API_URL;
const sliceName = "vendorData";

// APIs

export const getVendorData = createAsyncThunk(
  `${sliceName}/getVendorData`,
  async () =>
    await fetchWrapper.get(`${baseUrl}/vendor/get`)
);

export const getVendorDataById = createAsyncThunk(
  `${sliceName}/getVendorDataById`,
  async (apiParams) => {
    let id = apiParams?.id
    let params = undefined
    if (!id) {
      id = apiParams
    } else {
      params = generateUpdateApiQueryParams(apiParams?.params)
    }
    return await fetchWrapper.get(
      `${baseUrl}/vendor/get/${id}${params ? `?${params || ""}` : ''}`
    )
  }
);

export const getVendorRowData = createAsyncThunk(
  `${sliceName}/getVendorRowData`,
  async (params) => await fetchWrapper.get(`${baseUrl}/vendor/getAll?${params || ""}`)
);

export const getVendorMetaData = createAsyncThunk(
  `${sliceName}/getVendorMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/vendor/getMetadata`)
);

export const deleteVendor = createAsyncThunk(
  `${sliceName}/deleteVendor`,
  async ({ id }) => {
    const res = await fetchWrapper.post(`${baseUrl}/vendor/delete-permanent/${id}`);
    return res || res === "" ? { id } : null;
  }
);

export const duplicateVendor = createAsyncThunk(
  `${sliceName}/duplicateVendor`,
  async ({ id }) => await fetchWrapper.post(`${baseUrl}/vendor/duplicate/${id}`)
);

export const getVendorById = createAsyncThunk(
  `${sliceName}/getVendorById`,
  async (id) => await fetchWrapper.get(`${baseUrl}/vendor/get/${id}`)
);

export const addVendor = createAsyncThunk(
  `${sliceName}/addVendor`,
  async (values) => {
    const res = await fetchWrapper.post(`${baseUrl}/vendor/create`, values);
    return res || res === "" ? res : null;
  }
);

export const editVendor = createAsyncThunk(
  `${sliceName}/editVendor`,
  async (values) => {
    const res = await fetchWrapper.post(`${baseUrl}/vendor/edit/${values.id}`, values.formData);
    return res || res === "" ? res : null;
  }
);

export const getVendorMetaDataByIdForVersion = createAsyncThunk(
  `${sliceName}/getVendorMetaDataByIdForVersion`,
  async (params) => await fetchWrapper.get(`${baseUrl}/vendor/getMetadata?versioning=true&${params || ""}`)
);

export const getVendorRowDataByIdForVersion = createAsyncThunk(
  `${sliceName}/getVendorRowDataByIdForVersion`,
  async ({ params, id }) => await fetchWrapper.get(`${baseUrl}/vendor/version-history/get/${id}?${params || ""}`)
);

export const addVendorBulkUpdates = createAsyncThunk(
  `${sliceName}/addVendorBulkUpdates`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/vendor/bulk-update`,
      values
    );
    return res || res === "" ? res : null;
  }
);

// slice
export const vendorSlice = createSlice({
  name: sliceName,
  initialState: {},
});

export default vendorSlice.reducer;
