import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import FamilysVersionHistory from "views/pim/family/family/familyVersionHistory";
import { fetchWrapper } from "_utils";

const baseUrl = process.env.REACT_APP_VENDOR_FEED_API;
const name = "vendor_config";
const sliceName=name;


const initialState={
    loading:false,
    configs:[],
    config:{},
    error:"",
    queryPageIndex: 0,
    queryPageSize: 10,
    totalCount: null,
}

export const getConfigMetaData = createAsyncThunk(
    `${sliceName}/getUsersMetaData`,
    async () => await fetchWrapper.get(`${baseUrl}/configs/getMetadata`)
  );
  export const deleteConfig = createAsyncThunk(
    `${sliceName}/deleteUsers`,
    async ({ id }) => {
      const res = await fetchWrapper.post(`${baseUrl}/configs/delete/${id}`, { id });
      return res || res === "" ? { id } : null;
    }
  );
  export const getConfigRowData = createAsyncThunk(
    `${sliceName}/getUsersRowData`,
    async (params) => await fetchWrapper.get(`${baseUrl}/configs/getAll?${params || ""}`)
  );

  export const getStoneRowData = createAsyncThunk(
    `${sliceName}/getStoneData`,
    async (params) => {
        console.log("Parmas",params);
        return await fetchWrapper.get(`${baseUrl}/search-stones/v1?${params || ""}`)
    }
  );

  export const getStoneMetaData = createAsyncThunk(
    `${sliceName}/getUsersMetaData`,
    async () => await fetchWrapper.get(`${baseUrl}/search-stones/getMetadata`)
  );
  export const blockStoneData = createAsyncThunk(
    `${sliceName}/blockStoneData`,
    async ({params}) => {
        const response = await axios
        .patch(`${baseUrl}/block-stones`,JSON.stringify(params),{ headers: { "Content-Type": "application/json" } });
       
        return response.data;
    }
  );

  export const moveStoneData = createAsyncThunk(
    `${sliceName}/moveStoneData`,
    async ({params}) => {
        const response = await axios
        .patch(`${baseUrl}/move-stones`,JSON.stringify(params),{ headers: { "Content-Type": "application/json" } });
       
        return response.data;
    }
  );

export const fetchVendorsConfig=createAsyncThunk('vendor/fetchVendorConfig',async ({queryPageIndex, queryPageSize})=>{
   
   const offset = queryPageIndex+1;
    const response = await axios
        .get(`${baseUrl}/configs?page=${offset}&limit=${queryPageSize}`);
    return response.data;

})

export const deleteVendorConfigById=createAsyncThunk('vendor/deleteVendorConfig',async (vendor_id)=>{
    const response = await axios
        .delete(`${baseUrl}/configs/${vendor_id}`);
    return response.data;

})

export const getVendorConfigById=createAsyncThunk('vendor/getVendorConfigById',async (vendor_id)=>{
    const response = await axios
        .get(`${baseUrl}/configs/${vendor_id}`);
    return response.data;

})

export const createVendorConfig=createAsyncThunk('vendor/createVendorConfig',async (data)=>{
    const response = await axios
        .post(`${baseUrl}/configs`,JSON.stringify(data),{ headers: { "Content-Type": "application/json" } });
       
    return response.data;

})

export const updateVendorConfig=createAsyncThunk('vendor/updateVendorConfig',async ({data,id})=>{
   
    const response = await axios
        .patch(`${baseUrl}/configs/${id}`,JSON.stringify(data),{ headers: { "Content-Type": "application/json" } });
       
    return response.data;

})



const vendorConfigSlice=createSlice({
    name:"vendor",
    initialState,
    reducers:{
        setPageChanged: (state, action) => {
            return {...state, queryPageIndex: action.payload}
        },
        setPageSizeChanged: (state, action) => {
        return {...state, queryPageSize: action.payload}
        },

        setTotalCountChanged: (state, action) => {
        return {...state, totalCount: action.payload}
        },
    },
    extraReducers:(builder)=>{
        builder.addCase(fetchVendorsConfig.pending,(state)=>{
            state.loading=true
        })
        builder.addCase(fetchVendorsConfig.fulfilled,(state,action)=>{
            state.loading=false
            state.configs=action.payload.data
            state.totalCount=action.payload.count
            state.error='';
            state.config={}
        })
        builder.addCase(fetchVendorsConfig.rejected,(state,action)=>{
            state.loading=false
            state.configs=[]
            state.error=action.error.message;
            state.config={}
        })
        builder.addCase(createVendorConfig.pending,(state)=>{
            state.loading=true
        })
        builder.addCase(createVendorConfig.fulfilled,(state,action)=>{
            state.loading=false
            state.config=action.payload
            state.error='';
            state.configs=[]
        })
        builder.addCase(createVendorConfig.rejected,(state,action)=>{
            state.loading=false
            state.configs=[]
            state.error=action.error.message;
            state.config={}
        })
        builder.addCase(updateVendorConfig.pending,(state)=>{
            state.loading=true
        })
        builder.addCase(updateVendorConfig.fulfilled,(state,action)=>{
            state.loading=false
            state.config=action.payload
            state.error='';
            state.configs=[]
        })
        builder.addCase(updateVendorConfig.rejected,(state,action)=>{
            state.loading=false
            state.configs=[]
            state.error=action.error.message;
            state.config={}
        })
        builder.addCase(getVendorConfigById.pending,(state)=>{
            state.loading=true
        })
        builder.addCase(getVendorConfigById.fulfilled,(state,action)=>{
            state.loading=false
            state.config=action.payload
            state.error='';
            state.configs=[]
        })
        builder.addCase(getVendorConfigById.rejected,(state,action)=>{
            state.loading=false
            state.configs=[]
            state.error=action.error.message;
            state.config={}
        })

        builder.addCase(deleteVendorConfigById.pending,(state)=>{
            state.loading=true
        })
        builder.addCase(deleteVendorConfigById.fulfilled,(state,action)=>{
            state.loading=false
            state.config=action.payload
            state.error='';
            state.configs=state.configs.filter(vendor=>vendor.vendor_id!==action.meta.arg)
        })
        builder.addCase(deleteVendorConfigById.rejected,(state,action)=>{
            state.loading=FamilysVersionHistory
            state.error=action.error.message;
            state.config={}
        })
    }
})


export default vendorConfigSlice.reducer

export const {setPageChanged,setPageSizeChanged,setTotalCountChanged}= vendorConfigSlice.actions;

