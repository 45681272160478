import { store } from "_store";
import axios from "axios";
import { createAlert } from "_store/alert.slice";
import { logout, refreshToken } from "_store/auth.slice";

const baseUrl = process.env.REACT_APP_API_URL;

export const fetchWrapper = {
  get: request("GET"),
  fetch: request("FETCH"),
  post: request("POST"),
  put: request("PUT"),
  delete: request("DELETE"),
};

function request(method) {
  return (url, body, contentType = null, accept = null) => {
    const requestOptions = {
      method,
      headers: authHeader(url),
    };
    if (body) {
      requestOptions.headers["Content-Type"] = contentType ? contentType : "application/json";
      if(accept) requestOptions.headers["Accept"] = accept
      requestOptions.data = body;
      
    }

    return axios(url, requestOptions)
      .then((response) => handleSuccessResponse(response))
      .catch((error) => handleErrorResponse(error));
  };
}

// helper functions
function authHeader(url) {
  // return auth header with jwt if user is logged in and request is to the api url
  const token = authToken();
  const isLoggedIn = (token === null ? false : authToken());
  const isApiUrl = url.startsWith(process.env.REACT_APP_API_URL);
  if (isLoggedIn && isApiUrl) {
    return { Authorization: `Bearer ${token}` };
  } else {
    return {};
  }
}

export function authToken() {
  let token = localStorage.getItem("userToken");
  if (token && token?.split(".")?.[1] && atob) {
    const decode = JSON.parse(atob(token?.split(".")?.[1]));
    const isTokenInValid = decode.exp * 1000 < new Date().getTime();
    if (isTokenInValid) {
      store.dispatch(logout());
      return null;
    }
  }
  return token;
}

function handleSuccessResponse(response) {
  const data = response.data;
  return data;
}

function handleErrorResponse(response) {
  const data = response;
  const token = authToken();
  const hasToken = (token === null ? false : true);
  if ([401].includes(response.status ?? response.response.status) && !hasToken) {
    // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
    store.dispatch(logout());
  }
console.log(99, response);

  const error =
    (data && response.response.data.error.message) ||
    response.message ||
    response.statusText;
  store.dispatch(
    createAlert({ alertType: "error", msg: error, timeout: 5000 })
  );
  return Promise.reject(error);
}


// Add a request interceptor
axios.interceptors.request.use(
  (config) => {
    const token = authToken();
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    //console.log('request interceptor', config)
    return config;
  }, 
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // console.log('response interceptor', response)
    return response;
  }, async (err) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    //return Promise.reject(error);

    const originalConfig = err.config;
    const refreshAPI = `${baseUrl}/user/refreshJwt`
    const userLoginApi = `${baseUrl}/user/login`

    if (originalConfig.url === refreshAPI && err.response){
      store.dispatch(logout());
    }

    else if (originalConfig.url !== userLoginApi && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const token = authToken();
          if(token){
            const rs = await axios.post(refreshAPI, {
              refreshJwt: token ?? ""
            })

            const { accessToken } = rs.data;
  
            store.dispatch(refreshToken(accessToken))
            localStorage.setItem('userToken', accessToken);
            return axios(originalConfig);
          }
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }
    return Promise.reject(err);
  }
);