import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchWrapper } from "_utils";

// create slice
const baseUrl = process.env.REACT_APP_API_URL;
const sliceName = "shipmentTrackingData";

// APIs

export const getShipmentTrackingRowData = createAsyncThunk(
  `${sliceName}/getShipmentTrackingRowData`,
  async (params = "") =>
    await fetchWrapper.get(`${baseUrl}/shipment-tracking/getAll?${params}`)
);

export const getVendorDataById = createAsyncThunk(
  `${sliceName}/getVendorData`,
  async (id) =>
    await fetchWrapper.get(`${baseUrl}/vendor/get/${id}`)
);

export const getDeliveryServiceById = createAsyncThunk(
  `${sliceName}/getDeliveryServiceById`,
  async ({locationId, logisticProviderId}) =>
    await fetchWrapper.get(`${baseUrl}/location/${locationId}/logistic-provider/${logisticProviderId}/delivery-service/getAll`)
);

export const getVendorRowData = createAsyncThunk(
  `${sliceName}/getVendorRowData`,
  async (params) => await fetchWrapper.get(`${baseUrl}/vendor/getAll?${params || ""}`)
);

export const getShipmentTrackingMetaData = createAsyncThunk(
  `${sliceName}/getShipmentTrackingMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/shipment-tracking/getMetadata`)
);

export const deleteShipmentTracking = createAsyncThunk(
  `${sliceName}/deleteShipmentTracking`,
  async ({ id }) => {
    const res = await fetchWrapper.post(`${baseUrl}/vendor/delete-permanent/${id}`);
    return res || res === "" ? { id } : null;
  }
);

export const duplicateVendor = createAsyncThunk(
  `${sliceName}/duplicateVendor`,
  async ({ id }) => await fetchWrapper.post(`${baseUrl}/vendor/duplicate/${id}`)
);

export const getVendorById = createAsyncThunk(
  `${sliceName}/getVendorById`,
  async (id) => await fetchWrapper.get(`${baseUrl}/vendor/get/${id}`)
);

export const addShipmentTracking = createAsyncThunk(
  `${sliceName}/addShipmentTracking`,
  async (values) => {
    const res = await fetchWrapper.post(`${baseUrl}/shipment-tracking/add`, values);
    return res || res === "" ? res : null;
  }
);

export const editVendor = createAsyncThunk(
  `${sliceName}/editVendor`,
  async (values) => {
    const res = await fetchWrapper.post(`${baseUrl}/vendor/edit/${values.id}`, values.formData);
    return res || res === "" ? res : null;
  }
);

export const getVendorMetaDataByIdForVersion = createAsyncThunk(
  `${sliceName}/getVendorMetaDataByIdForVersion`,
  async (params) => await fetchWrapper.get(`${baseUrl}/vendor/getMetadata?versioning=true&${params || ""}`)
);

export const getVendorRowDataByIdForVersion = createAsyncThunk(
  `${sliceName}/getVendorRowDataByIdForVersion`,
  async ({ params, id }) => await fetchWrapper.get(`${baseUrl}/vendor/version-history/get/${id}?${params || ""}`)
);


export const getShipmentTrackingInternalStateMetaData = createAsyncThunk(
  `${sliceName}/getShipmentTrackingInternalStateMetaData`,
  async () =>
    await fetchWrapper.get(`${baseUrl}/shipment-tracking-internal-states/getMetadata`)
);

export const getShipmentTrackingInternalStateRowData = createAsyncThunk(
  `${sliceName}/getShipmentTrackingInternalStateMetaData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/shipment-tracking-internal-states/getAll?${params || ""}`)
);

export const deleteShipmentTrackingInternalState = createAsyncThunk(
  `${sliceName}/deleteShipmentTrackingInternalState`,
  async ({ id }) => await fetchWrapper.post(`${baseUrl}/shipment-tracking-internal-states/delete/${id}`, { id })
);

export const getShipmentTrackingInternalStateById = createAsyncThunk(
  `${sliceName}/getShipmentTrackingInternalStateById`,
  async (id) => await fetchWrapper.get(`${baseUrl}/shipment-tracking-internal-states/get/${id}`)
);

export const addShipmentTrackingInternalStates = createAsyncThunk(
  `${sliceName}/addShipmentTrackingInternalStates`,
  async (values) => {
    const res = await fetchWrapper.post(`${baseUrl}/shipment-tracking-internal-states/add`, values);
    return res || res === "" ? res : null;
  }
);

export const editShipmentTrackingInternalStates = createAsyncThunk(
  `${sliceName}/editShipmentTrackingInternalStates`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/shipment-tracking-internal-states/update/${values.id}`,
      values
    );
    return res || res === "" ? values : null;
  }
);

// slice
export const shipmentTrackingSlice = createSlice({
  name: sliceName,
  initialState: {},
});

export default shipmentTrackingSlice.reducer;
