import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import FamilysVersionHistory from "views/pim/family/family/familyVersionHistory";
import { fetchWrapper } from "_utils";


const baseUrl = process.env.REACT_APP_VENDOR_FEED_API;
const sliceName = "cron";

const initialState = {
    loading: false,
    crons: [],
    cron:{},
    error: "",
    queryPageIndex: 0,
    queryPageSize: 10,
    totalCount: null,
}

export const getCronMetaData = createAsyncThunk(
    `${sliceName}/getUsersMetaData`,
    async () => await fetchWrapper.get(`${baseUrl}/jobs/getMetadata`)
  );
  export const deleteCron = createAsyncThunk(
    `${sliceName}/deleteUsers`,
    async ({ id }) => {
      const res = await fetchWrapper.post(`${baseUrl}/jobs/delete/${id}`, { id });
      return res || res === "" ? { id } : null;
    }
  );
  export const getCronsRowData = createAsyncThunk(
    `${sliceName}/getUsersRowData`,
    async (params) => await fetchWrapper.get(`${baseUrl}/jobs/getAll?${params || ""}`)
  );

export const fetchCronJobs = createAsyncThunk('cron/fetchCronJobs', async ({isActive,queryPageIndex, queryPageSize}) => {
    const offset = queryPageIndex+1;
    const url = `${baseUrl}/jobs?isActive=${isActive}&page=${offset}&limit=${queryPageSize}`
    const response = await axios.get(url);
    return response.data;

})

export const createCron = createAsyncThunk('cron/createCron', async (data) => {
    const response = await axios
        .post(`${baseUrl}/jobs`, JSON.stringify(data), { headers: { "Content-Type": "application/json" } });
    return response.data;
})

export const updateCron = createAsyncThunk('cron/updateCron', async ({ data, id }) => {
    const response = await axios
        .patch(`${baseUrl}/jobs/${id}`, JSON.stringify(data), { headers: { "Content-Type": "application/json" } });
    return response.data;

})

export const getCronById = createAsyncThunk(
    `${sliceName}/getCronById`, async ({ id }) => {
        const response = await axios
            .get(`${baseUrl}/jobs/${id}`);
        return response.data;
    }
);

export const deleteCronById = createAsyncThunk(
    `${sliceName}/deleteCronById`, async ({ id }) => {
        const response = await axios
            .delete(`${baseUrl}/jobs/${id}`);
        return response.data;
    }
);

export const fetchVendorConfig = createAsyncThunk(
    `${sliceName}/fetchVendorConfig`, async () => {
        const response = await axios
            .get(`${baseUrl}/vendor/config`);
        return response.data;
    }
);

const cronSlice = createSlice({
    name: "cron",
    initialState,
    reducers: {
        setPageChanged: (state, action) => {
            return {...state, queryPageIndex: action.payload}
        },
        setPageSizeChanged: (state, action) => {
        return {...state, queryPageSize: action.payload}
        },

        setTotalCountChanged: (state, action) => {
        return {...state, totalCount: action.payload}
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCronJobs.pending, (state) => {
            state.loading = true
        })
        builder.addCase(fetchCronJobs.fulfilled, (state, action) => {
            state.loading = false
            state.crons = action.payload.jobs
            state.totalCount=action.payload.count
            state.error = '';
            state.cron={}
        })
        builder.addCase(fetchCronJobs.rejected, (state, action) => {
            state.loading = false
            state.crons = []
            state.cron={}
            state.error = action.error.message;
        })
        builder.addCase(createCron.pending, (state) => {
            state.loading = true
        })
        builder.addCase(createCron.fulfilled, (state, action) => {
            state.loading = false
            state.cron = action.payload
            state.crons=[]
            state.error = '';
        })
        builder.addCase(createCron.rejected, (state, action) => {
            state.loading = false
            state.crons = []
            state.error = action.error.message;
            state.cron={}
        })
        builder.addCase(updateCron.pending, (state) => {
            state.loading = true
        })
        builder.addCase(updateCron.fulfilled, (state, action) => {
            state.loading = false
            state.cron = action.payload
            state.error = '';
            state.crons = []
        })
        builder.addCase(updateCron.rejected, (state, action) => {
            state.loading = false
            state.crons = []
            state.error = action.error.message;
            state.cron = {}
        })
        builder.addCase(deleteCronById.pending,(state)=>{
            state.loading=true
        })
        builder.addCase(deleteCronById.fulfilled,(state,action)=>{
            state.loading=false
            state.cron=action.payload
            state.error='';
            state.crons=state.crons.filter(cron=>cron.id!==action.meta.arg.id)
        })
        builder.addCase(deleteCronById.rejected,(state,action)=>{
            state.loading=FamilysVersionHistory
            state.error=action.error.message;
            state.cron={}
        })
    }
})


export default cronSlice.reducer

export const {setPageChanged,setPageSizeChanged,setTotalCountChanged}= cronSlice.actions;

